import "../front/styles/styles.css";
import "../styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/ja";

import { UserProvider, UserProfile } from "@auth0/nextjs-auth0";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner, fas } from "@fortawesome/free-solid-svg-icons";
import type { AppProps } from "next/app";
import Script from "next/script";
import "tailwindcss/tailwind.css";
import ApolloWrapper from "../ApolloWrapper";

library.add(faSpinner, fas);

function MyApp({ Component, pageProps }: AppProps<{ user: UserProfile }>) {
  const { user } = pageProps;
  return (
    <UserProvider user={user}>
      <Script src="/front/js/libs.js" strategy="beforeInteractive" />
      <Script src="/front/js/scripts.js" strategy="afterInteractive" />
      <ApolloWrapper>
        <Component {...pageProps} />
      </ApolloWrapper>
    </UserProvider>
  );
}

export default MyApp;
